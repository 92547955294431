/* label stuff */

.text-labels {
  font-size: 3vmin !important;
  font-weight: 400 !important;
  color: white;
  font: bold 42px Helvetica, Arial, Sans-Serif;
  text-shadow: 1px 1px #c919ba, 1px 1px #c919ba, 2px 2px #c919ba;
}

/*TODO in main.js*/

.text-labels-hover-effect {
  position: relative;
  top: -3px;
  left: -3px;
  text-shadow: 1px 1px #c919ba, 2px 2px #c919ba, 3px 3px #c919ba, 4px 4px #c919ba, 5px 5px #c919ba;
}
