.control-image {
  height: 100%;
  width: 100%;
}

.margin-bottom {
  margin-bottom: 5vh !important;
}

.flatpickr-calendar {
  top: 175px !important;
  left: 50px !important;
  width: 322px !important;
  border: 2px solid rgba(0, 0, 0, 0.2) !important;
  background-color: rgba(255, 255, 255, 0.7) !important;
  margin: 0 !important;
  box-shadow: none !important;
}

.flatpickr-calendar::before {
  display: none !important;
}
.flatpickr-calendar::after {
  display: none !important;
}

.flatpickr-rContainer {
  margin: 0 auto;
}

.flatpickr-confirm {
  height: 40px;
  max-height: 0px;
  visibility: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.06);
}

.flatpickr-confirm svg path {
  fill: inherit;
}

.flatpickr-confirm.darkTheme {
  color: white;
  fill: white;
}

.flatpickr-confirm.visible {
  max-height: 40px;
  visibility: visible;
}

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  background: #c919ba !important;
  border-color: #c919ba !important;
  -webkit-box-shadow: none;
  box-shadow: none;

  border-radius: 5px !important;
}

.flatpickr-day {
  border: none !important;
  border-radius: 5px !important;
  background: #e9e9ed !important;
  box-shadow: 0 3px #999 !important;
}

.flatpickr-day:hover {
  background: #c919ba !important;
}

.flatpickr-disabled {
  color: rgba(57, 57, 57, 0.5) !important;
  background: transparent !important;
  box-shadow: none !important;
}

.flatpickr-disabled:hover {
  box-shadow: none !important;
  background: none !important;
}

.flatpickr-day.selected {
  box-shadow: 0 3px #999 !important;
}

.service-button-container {
  display: flex;
  margin: 2%;
}

.time-button-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin: 2%;
  padding-bottom: 2%;
}

.button-title {
  margin-top: 5%;
  font-weight: bold;
}

.time-button {
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  outline: none;
  border: none;
  border-radius: 5px;
  box-shadow: 0 3px #999;
  margin: 4%;
  padding: 2%;
}

.service-button {
  flex: 1;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  outline: none;
  border: none;
  border-radius: 5px;
  box-shadow: 0 3px #999;
  margin: 1%;
  padding: 1%;
}

button:hover {
  background: #c919ba;
}

.button-active {
  background-color: #c919ba;
  color: #fff;
}

.echtDate-selection {
  color: rgba(64, 72, 72, 0.3);
  background: transparent;
  border-color: #e9e9e9;
  cursor: default;
}

.flatpickr-time-separator {
  display: none;
}

.numInputWrapper:nth-of-type(2n) {
  display: none;
}

@media only screen and (max-width: 665px) {
  .flatpickr-calendar {
    font-size: 12px !important;
  }
}
