.header-grid-container {
  background-color: #c919ba;
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  grid-template-areas: 'title  info  logo';
  font-size: 16px;
  color: white;
  padding: 5px;
  height: 80px;
}

.grid-item {
  display: flex;
  align-items: center;
  justify-content: center;
}

.title {
  font-size: 26px;
  grid-area: title;
  justify-content: start;
  font-weight: bold;
}

.title a {
  text-decoration: none;
  color: white;
}

.info {
  grid-area: info;
  display: flex;
  justify-content: space-evenly;
  justify-self: start;
  justify-content: baseline;
  width: 100%;
}

.logo {
  grid-area: logo;
  justify-content: end;
}

img {
  width: 100%;
  height: 60px;
}

th {
  border-bottom: 1px solid #ddd;
  min-width: 100px;
}

td {
  text-align: center;
  min-width: 100px;
}

table {
  width: 100%;
}

@media only screen and (max-width: 1220px) {
  .header-grid-container {
    grid-template-columns: auto;
    grid-template-areas: 'info ';
  }
  .logo,
  .title {
    display: none;
  }
}

@media only screen and (max-width: 865px) {
  .header-grid-container {
    grid-template-columns: auto;
    grid-template-areas: 'info ';
    font-size: 12px;
  }
}

@media only screen and (max-width: 665px) {
  .header-grid-container {
    display: flex;

    font-size: 12px;
  }
}
